import { Button, CloseButton, NotificationInline } from '@sumup/circuit-ui';
import { useRouter } from 'next/router';
import type React from 'react';
import {
  type ChangeEvent,
  type FunctionComponent,
  useEffect,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { LanguageSelect } from '../../components/LanguageSelect';
import { Logo as SumUpLogo } from '../../components/Logo';
import { config } from '../../lib/config/client';
import { cookiesEnabled } from '../../lib/cookies';
import {
  createLocaleCookie,
  getLanguageLocale,
  isLocale,
  safeLocale,
  statusUrl,
} from '../../services/i18n';

import { ROOT_SELECTOR } from './constants';
import styles from './styles.module.css';

import { Status } from 'components/Status';
import type { AppProps } from 'types/app-props';

export { Box } from './Box';

const KIOSK_MODE_CLOSE_SSO_URL = 'kiosk://auth-closed';

type AuthProps = AppProps & {
  hideFooter?: boolean;
  renderExtraColumn?: React.ReactNode;
};

const Auth: FunctionComponent<React.PropsWithChildren<AuthProps>> = ({
  children,
  renderAppUI,
  iframeUri,
  iframeTitle,
  kioskMode,
  hideFooter = false,
  renderExtraColumn,
}) => {
  const intl = useIntl();
  const router = useRouter();
  const locale = safeLocale(intl.locale);
  const languageLocale = getLanguageLocale(locale);
  const [supportsCookies, setSupportsCookies] = useState(true);

  useEffect(() => {
    setSupportsCookies(cookiesEnabled());
  }, []);

  const handleLocaleChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    e.preventDefault();
    const newLocale = e.target.value;

    if (!isLocale(newLocale)) {
      return;
    }

    document.cookie = createLocaleCookie(
      'PORTIER_LOCALE',
      newLocale,
      config.localeCookieDomain,
    );
    document.cookie = createLocaleCookie(
      'NEXT_LOCALE',
      newLocale,
      config.localeCookieDomain,
    );

    void router.push(
      {
        pathname: router.pathname,
        query: router.query,
      },
      undefined,
      { locale: newLocale.toLowerCase() },
    );
  };

  const iframeURIAllowed = (uri: string): boolean =>
    uri.startsWith('https://store.sumup.com/') ||
    /^https:\/\/storefront-\w+\.sumup-vercel\.app\//.test(uri);

  const hasIframe = !!iframeUri && !!iframeTitle && iframeURIAllowed(iframeUri);
  const hasAdditionalColumn = !!renderExtraColumn || hasIframe;

  return (
    <div className={styles.content}>
      <Status />
      <header className={styles.header}>
        {!kioskMode ? (
          <SumUpLogo renderLink={!renderAppUI} />
        ) : (
          <CloseButton
            className={styles.closeButton}
            href={KIOSK_MODE_CLOSE_SSO_URL}
          >
            {intl.formatMessage({
              defaultMessage: 'Close',
              description:
                'Accessibility label for the optional kiosk mode close button rendered on the Register app.',
            })}
          </CloseButton>
        )}
      </header>
      <main className={styles.main}>
        <div
          className={
            hasAdditionalColumn ? styles.authMainExtraColumn : styles.authMain
          }
        >
          <div
            className={
              hasAdditionalColumn ? styles.wrapperExtraColumn : styles.wrapper
            }
          >
            {!supportsCookies && (
              <NotificationInline
                variant="danger"
                className={styles.notification}
                body={intl.formatMessage({
                  defaultMessage:
                    "We've detected a problem with your cookie settings. To make sure this page works properly please enable cookies in your browser.",
                  description:
                    'Notification message shown on the page if the user has cookies disabled.',
                })}
              />
            )}
            <noscript>
              <NotificationInline
                variant="danger"
                className={styles.notification}
                body={intl.formatMessage({
                  id: 'notifications.javascript_disabled',
                  defaultMessage:
                    'To make sure this page works properly, please use a browser that supports javascript, or enable javascript in your browser settings.',
                  description:
                    'Notification message shown on the page if javascript is disabled.',
                })}
              />
            </noscript>
            {children}
          </div>
          {hasIframe && (
            <div>
              <div className={styles.iframeContainer}>
                <iframe
                  className={styles.iframe}
                  src={iframeUri}
                  title={iframeTitle}
                  sandbox="allow-same-origin allow-scripts"
                />
              </div>
            </div>
          )}
          {!!renderExtraColumn && renderExtraColumn}
        </div>
      </main>
      {!hideFooter && (
        <footer className={styles.footer}>
          {!renderAppUI && (
            <LanguageSelect
              onChange={handleLocaleChange}
              label={intl.formatMessage({
                defaultMessage: 'Language',
                description: 'language select label for screen readers',
              })}
              locale={languageLocale}
              name={'languageSelect'}
              data-selector={`${ROOT_SELECTOR}-language-select`}
              className={styles.languageSelect}
            />
          )}
          {!kioskMode && (
            <Button variant="secondary" href={statusUrl}>
              {intl.formatMessage({
                defaultMessage: 'System status',
                description: 'System status link',
              })}
            </Button>
          )}
        </footer>
      )}
    </div>
  );
};

export default Auth;
