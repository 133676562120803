import { Select, type SelectProps } from '@sumup/circuit-ui';
import type { FunctionComponent } from 'react';

import type { Locale } from '../../services/i18n';

import { options } from './options';

export type LanguageSelectProps = {
  locale: Locale;
  name?: string;
  label: string;
} & SelectProps;

/**
 * A simple select component for our supported languages.
 */
const LanguageSelect: FunctionComponent<LanguageSelectProps> = ({
  locale,
  label,
  name = 'languageSelect',
  ...props
}) => (
  <Select
    {...{
      options,
      label,
      hideLabel: true,
      name,
      id: name,
      ...props,
      value: locale,
    }}
  />
);

export default LanguageSelect;
